import { groupBy, reduce } from 'lodash';
import { ROOFTOP_ID } from 'site-modules/shared/constants/allowed-seo-srp-request-params';

export function transformFacetsToUrlParams(facets, modifiedRadius, dealerId, deltaRadius) {
  let radius = deltaRadius ? { radius: deltaRadius } : {};
  radius = modifiedRadius ? { radius: modifiedRadius } : radius;

  return {
    ...reduce(
      groupBy(facets, 'type'),
      (res, filterData, type) => ({
        ...res,
        [type]: filterData.map(data => data.value),
      }),
      {}
    ),
    [ROOFTOP_ID]: dealerId,
    ...radius,
  };
}
