import { get } from 'lodash';
import { buildDisplayVehicle } from 'site-modules/shared/utils/core-page/display-name-builders';
import {
  getDefaultStockPhotoBySize,
  getImageUrl,
  getStaticImageUrl,
  getYoutubePreviewImage,
  SOURCES,
  STOCKPHOTOS_THRESHOLD_YEAR,
  YOUTUBE_PREVIEW_SIZE,
  isDamSrc,
  isContentSrc,
  getFullImageUrl,
} from 'client/utils/image-helpers';
import { NO_VEHICLE_GRAY_IMAGE_SEDAN } from 'site-modules/shared/constants/images';

export const EDMUNDS_CONTENT = 'edmunds_content';

const preferredHeroChoices = {
  f34: true,
  prf: true,
  r34: true,
  FQ: true,
  RQ: true,
  S: true,
  F: true,
  R: true,
  FBDG: true,
  RBDG: true,
  PR: true,
  PDO: true,
  BPO: true,
  DSP: true,
  HL: true,
  TLB: true,
  DH: true,
  DFQ: true,
  FHQ: true,
  FLV: true,
  RQN: true,
  FQN: true,
  FQL: true,
  FQWT: true,
  FS: true,
  RST: true,
  DQF: true,
  PRQ: true,
  SM: true,
  DSF: true,
  PL: true,
  GR: true,
  FPWT: true,
  RQH: true,
  TDS: true,
  TDS2: true,
  TDS3: true,
};

export const preferredPriority = ['f34', 'prf', 'FQ', 'S', 'FQN', 'DFQ', 'PR', 'r34', 'RQ'];
export const preferredFallbackPriority = [
  'TDS',
  'FQH',
  'FQWT',
  'FQL',
  'FPWT',
  'TDS2',
  'PRQ',
  'DSP',
  'RQH',
  'TDS3',
  'F',
  'FS',
];

function getBanquetHeroPhoto({ make, model, year }, vehicleName, { width, height }) {
  return {
    src: getDefaultStockPhotoBySize({ make, model, year }, width, height),
    num: 1,
    title: vehicleName,
    type: 'photo',
    category: 'all',
    provider: 'OEM',
    shottype: 'BANQUET',
    size: { width, height },
  };
}

export function getNoVehiclePhoto(vehicleName) {
  return {
    src: getStaticImageUrl(NO_VEHICLE_GRAY_IMAGE_SEDAN),
    num: 1,
    title: vehicleName,
    type: 'photo',
    category: 'all',
    provider: 'OEM',
    shottype: 'BANQUET',
    size: { width: 600, height: 400 },
  };
}

function getEditorialReviewHeroPhoto(editorialReviewHero, vehicleName, { width, height }) {
  if (!editorialReviewHero?.href) {
    return null;
  }

  const imageSrc = getFullImageUrl(editorialReviewHero?.href, width);
  const isEdmundsPhoto = editorialReviewHero?.rel === 'edmundsHero';

  return {
    src: imageSrc,
    num: undefined,
    title: editorialReviewHero?.title || vehicleName,
    author: isEdmundsPhoto ? editorialReviewHero?.author : undefined,
    type: 'photo',
    category: 'all',
    provider: isEdmundsPhoto ? EDMUNDS_CONTENT : 'OEM',
    shottype: 'N/A',
    size: { width, height },
    isLifestylePhoto: true,
  };
}

function getPreprodPhotos(media, vehicleName, { width, height }) {
  if (!media) {
    return [];
  }

  return media
    .filter(photo => photo.href && (isDamSrc(photo.href) || isContentSrc(photo.href)))
    .map((src, index) => ({
      src: getImageUrl(src.href, width, isDamSrc(src.href) ? SOURCES.DAM : SOURCES.CONTENTSTACK),
      num: index + 1,
      title: vehicleName,
      type: 'photo',
      category: 'all',
      provider: 'OEM',
      shottype: 'N/A',
      size: { width, height },
      isLifestylePhoto: true,
    }));
}

export function getPreprodVideos(media, vehicleName) {
  if (!media) {
    return [];
  }

  return media
    .filter(video => video.rel === 'video_youtube')
    .map((src, index) => ({
      type: 'video',
      title: vehicleName,
      num: index + 1,
      category: 'all',
      src: src.href,
      thumbnailURL: getYoutubePreviewImage(src.href, YOUTUBE_PREVIEW_SIZE.HQ),
      pageNum: '1',
      contentType: 'youtube',
    }));
}

function filterPreferred(photosObj) {
  const filteredList = {};

  Object.keys(photosObj).forEach(photo => {
    if (preferredHeroChoices[photo.split('_')[0]]) {
      filteredList[photo] = photosObj[photo];
    }
  });

  return filteredList;
}

export function transformPhotos(photos) {
  const shotTypes = {};

  if (get(photos, 'data.length')) {
    photos.data.forEach((photo, index) => {
      const photoData = { ...photo, num: index + 1 };

      if (shotTypes[photo.shottype]) {
        shotTypes[`${photo.shottype}_ALT${index}`] = photoData;
      } else {
        shotTypes[photo.shottype] = photoData;
      }
    });
  }
  return filterPreferred(shotTypes);
}

export function getCoreMedia({
  params,
  vehicle,
  firstContent,
  editorialReviewHero,
  photos,
  isPreprod,
  defaultSize = { width: 815, height: 543 },
}) {
  const isMatchingFirstContentSubmodel = !params.submodel || get(firstContent, 'isSubmodelSpecific');
  const vehicleName = buildDisplayVehicle({
    makeModelSubmodelYear: vehicle,
    params,
    trimDuplicateSubmodel: true,
  });
  const preprodImages =
    (isMatchingFirstContentSubmodel || isPreprod) &&
    getPreprodPhotos(get(firstContent, 'media'), vehicleName, defaultSize);
  const preprodVideos = isPreprod && getPreprodVideos(get(firstContent, 'media'), vehicleName);
  const heroChoices = isPreprod ? preprodImages : transformPhotos(photos);
  const exteriorPhotos = isPreprod ? preprodImages : Object.values(heroChoices).filter(photo => photo !== null);

  const heroChoiceShotType = preferredPriority.find(shotType => get(heroChoices, shotType));

  const banquetHero = getBanquetHeroPhoto(params, vehicleName, defaultSize);
  const noBanquetShotType = preferredFallbackPriority.find(shotType => get(heroChoices, shotType));
  const noBanquetHero = noBanquetShotType ? heroChoices[noBanquetShotType] : get(photos, 'data.[0]');

  // Remove num for not preprod to leave image in displayPhotos
  const firstPhoto = get(preprodImages, '[0]')
    ? { ...preprodImages[0], num: isPreprod ? get(preprodImages[0], 'num') : undefined }
    : null;
  const editorialReviewHeroPhoto = getEditorialReviewHeroPhoto(editorialReviewHero, vehicleName, defaultSize);

  const heroImage =
    editorialReviewHeroPhoto ||
    heroChoices[heroChoiceShotType] ||
    firstPhoto ||
    (params.year < STOCKPHOTOS_THRESHOLD_YEAR ? noBanquetHero : banquetHero) ||
    getNoVehiclePhoto(vehicleName);

  const displayPhotos =
    heroImage.shottype !== 'BANQUET' ? exteriorPhotos.filter(photo => photo.num !== heroImage.num) : exteriorPhotos;
  const totalCount = isPreprod ? displayPhotos.length : get(photos, 'totalCount', 0);
  const allMedia = { video: preprodVideos, photo: isPreprod ? exteriorPhotos : displayPhotos };

  return {
    heroImage,
    totalCount,
    allMedia,
    displayPhotos,
  };
}
