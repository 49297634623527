import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './circular-progress-bar.scss';

const VIEWBOX_WIDTH = 100;
const VIEWBOX_HEIGHT = 100;
const VIEWBOX_HEIGHT_HALF = 50;
const STROKE_WIDTH = 4;
const RADIUS = VIEWBOX_HEIGHT_HALF - STROKE_WIDTH / 2;
const VIEWBOX_CENTER_X = 50;
const VIEWBOX_CENTER_Y = 50;

export class CircularProgressBar extends Component {
  static propTypes = {
    classes: PropTypes.shape({}),
    className: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    classes: {
      root: 'circular-progress-bar',
      children: 'circular-progress-bar-children',
    },
    className: '',
    children: null,
  };

  render() {
    const { className, classes, children } = this.props;

    return (
      <div className="circular-progress-bar-container pos-r w-100 h-100">
        <svg className={classNames(classes.root, className)} viewBox={`0 0 ${VIEWBOX_WIDTH} ${VIEWBOX_HEIGHT}`}>
          <circle className="circle" r={RADIUS} cx={VIEWBOX_CENTER_X} cy={VIEWBOX_CENTER_Y} />
        </svg>
        {children ? (
          <div
            className={classNames(
              'w-100 h-100 d-flex flex-column justify-content-center align-items-center',
              classes.children
            )}
          >
            {children}
          </div>
        ) : null}
      </div>
    );
  }
}
