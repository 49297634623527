import { get, compact } from 'lodash';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { objectToQueryString } from 'site-modules/shared/utils/string';

/**
 * @typedef {Object} CoreLinkVehicle
 * @property {string} makeSlug
 * @property {string} modelSlug
 * @property {string} year
 * @property {string} [submodelSlug] - optional
 */

/**
 * @param {@see VehicleEntities.MakeModelSubmodelYear} makeModelSubmodelYear
 * @param {@see CorePageParams} params
 * @return {null || CoreLinkVehicle} - vehicle for use in link constructor methods.
 */
export function transformMmsyAndParams({ makeModelSubmodelYear, params }) {
  if (!makeModelSubmodelYear) {
    return null;
  }

  const transformedVehicle = {
    makeSlug: get(makeModelSubmodelYear, 'make.slug'),
    modelSlug: get(makeModelSubmodelYear, 'model.slug'),
    year: get(makeModelSubmodelYear, 'year'),
  };

  if (params && params.submodel) {
    transformedVehicle.submodelSlug = get(makeModelSubmodelYear, 'submodels.slug');
  }

  return transformedVehicle;
}

/**
 * Generate no submodel link to core page from vehicle object
 *
 * @param {CoreLinkVehicle}
 * @return {string}
 */
export function getNoSubmodelCoreUrl({ makeSlug, modelSlug, year }) {
  if (!makeSlug || !modelSlug || !year) {
    throw new ReferenceError('Make, model slugs and year should be defined');
  }
  return `/${makeSlug}/${modelSlug}/${year}/`;
}

/**
 * Generate link to core page from vehicle object
 *
 * @param {CoreLinkVehicle}
 * @return {string}
 */
export function getCoreUrl({ makeSlug, modelSlug, year = '', submodelSlug = '', defaultYear, defaultSubmodel }) {
  const throwError = !makeSlug || !modelSlug;
  const urlSubmodel = submodelSlug === defaultSubmodel ? '' : submodelSlug;
  const urlYear = parseInt(year, 10) === defaultYear && !urlSubmodel ? '' : year;

  if (throwError) {
    throw new ReferenceError('Make and model slugs should be defined');
  }

  return `/${compact([makeSlug, modelSlug, urlYear, urlSubmodel]).join('/')}/`;
}

/**
 * Build link to core/write-review.html
 * @param {CoreLinkVehicle} vehicleMMY
 * @return {string}
 */
export function getWriteReviewUrl(vehicleMMY) {
  const { makeSlug, modelSlug, year } = vehicleMMY;
  return `${getCoreUrl({ makeSlug, modelSlug, year })}write-review/`;
}

/**
 * Build link to core/{{ path }}
 * @param {Object} vehicleMMYS - @see VehicleEntities.MakeModelSubmodelYear
 * @param {String} path - custom destination
 * @return {string}
 */
export function getCustomCoreUrl(vehicleMMYS, path) {
  return `${getCoreUrl(vehicleMMYS)}${path}`;
}

/**
 * Build link to make/model/year/cost-to-own/
 * @param {Object} vehicleMMYS
 * @param {Number} styleId
 * @return {String}
 */
export function getTCOUrl(vehicleMMYS, styleId) {
  const st = styleId ? `?style=${styleId}` : '';
  return `${getCoreUrl(vehicleMMYS)}cost-to-own/${st}`;
}

export function getVehicleComparisonUrl(styleIds) {
  return `/car-comparisons/?${styleIds
    .slice(0, 4)
    .map((styleId, index) => `veh${index + 1}=${styleId}`)
    .join('&')}`;
}

/**
 * Build link to /car-comparisons/{make1}-{model1}-vs-{make2}-{model2}/
 * @param {Object} vehicle1
 * @param {Object} vehicle2
 * @return {string}
 */
export function getMakeModelVsMakeModelUrl(vehicle1, vehicle2) {
  return `/car-comparisons/${makeNiceName(vehicle1.make)}-${makeNiceName(vehicle1.model)}-vs-${makeNiceName(
    vehicle2.make
  )}-${makeNiceName(vehicle2.model)}/`;
}

/**
 * Build link to core vehicle comparison
 * @param {CoreLinkVehicle} vehicleMMY
 * @return {string}
 */
export function getConsumerReviewsUrl(vehicleMMY) {
  return `${getNoSubmodelCoreUrl(vehicleMMY)}${vehicleMMY.styleId ? `st-${vehicleMMY.styleId}/` : ''}consumer-reviews/`;
}

/**
 * Build link to core/review/
 *
 * @param {CoreLinkVehicle} vehicleMMY
 * @return {string}
 */
export function getReviewUrl(vehicleMMY) {
  return `${getCoreUrl(vehicleMMY)}review/`;
}

export function getDealsUrl(vehicleMMY, { styleId, section } = {}) {
  const query = objectToQueryString({
    defaultStyleId: styleId,
    defaultSection: section,
  });

  return `${getCoreUrl(vehicleMMY)}deals/${query ? `?${query}` : ''}`;
}

export function getFeaturesSpecsUrlStandard(vehicle, styleId) {
  return `${getCoreUrl(vehicle)}${styleId ? `st-${styleId}/` : ''}features-specs/`;
}

export function getMsrpUrl({ makeSlug, modelSlug, year }) {
  return `/${makeSlug}/${modelSlug}/${year}/msrp/`;
}

export function getTrimsComparisonUrl({ makeSlug, modelSlug, year }) {
  return `/${makeSlug}/${modelSlug}/${year}/trims/`;
}

export function getMpgUrl({ makeSlug, modelSlug, year, styleId }) {
  return `/${makeSlug}/${modelSlug}/${year}/mpg/${styleId ? `?style=${styleId}` : ''}`;
}

export function getEvIncentivesUrl({ makeName, modelName, incentiveType, vehCondition }) {
  const query = objectToQueryString({ make: makeName, model: modelName, incentiveType, vehCondition });

  return `/electric-car/tax-credits-rebates-incentives/${query ? `?${query}` : ''}`;
}
