import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './global-search-loading-separator.scss';

export const GlobalSearchLoadingSeparator = ({ isMobile, showAnimation }) => (
  <div
    className={classnames('global-search-separator pos-a top-0 left-0 right-0', {
      mobile: isMobile,
    })}
  >
    {!isMobile && <hr className="my-0" />}

    {showAnimation && <div className="animated-separator pos-a top-0" />}
  </div>
);

GlobalSearchLoadingSeparator.propTypes = {
  isMobile: PropTypes.bool,
  showAnimation: PropTypes.bool,
};

GlobalSearchLoadingSeparator.defaultProps = {
  isMobile: false,
  showAnimation: false,
};
