export const abortControllerManager = {
  controllers: [],

  register(controller) {
    this.controllers.push(controller);
  },

  abort() {
    this.controllers.forEach(controller => controller.abort());
  },

  reset() {
    this.controllers = [];
  },
};
