import { useMemo } from 'react';
import { debounce } from 'lodash';

/**
 * Custom hook that will debounce any given function.
 *
 * @param {function} callback - The function that should be debounced.
 * @param {number} delay - The amount of time in ms before the callback should be invoked.
 * @param {Object} options - Optional. Configuration options for the debounce function.
 *                           Refer to lodash documentation for additional details.
 *                           https://lodash.com/docs/4.17.15#debounce
 *
 * @returns {function} - The debounced function that will be returned from the hook.
 */
export const useDebounce = (callback, delay, options) =>
  useMemo(() => debounce(callback, delay, options), [callback, delay, options]);
