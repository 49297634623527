import { useEffect, useRef } from 'react';
import { DialogTrapFocusHelper } from 'site-modules/shared/utils/global-search/dialog-trap-focus-helper';

export const useDialogFocusTrap = containerRef => {
  const dialogTrapFocusHelper = useRef(new DialogTrapFocusHelper());

  useEffect(() => {
    const dialogTrapFocusHelperNode = dialogTrapFocusHelper.current;
    dialogTrapFocusHelperNode.init(containerRef.current);

    return () => {
      dialogTrapFocusHelperNode.clear();
    };
  }, [containerRef]);
};
