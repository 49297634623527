const KEYCODE_ARROW_DOWN = 40;
const KEY_ARROW_DOWN = 'ArrowDown';
const KEYCODE_ARROW_UP = 38;
const KEY_ARROW_UP = 'ArrowUp';
const FOCUSABLE_ELEMENTS = ['a[href]', 'button'].map(element => `${element}:not([disabled])`).join(', ');

function getFocusableElements(activeDialog) {
  return activeDialog ? Array.from(activeDialog.querySelectorAll(FOCUSABLE_ELEMENTS)) : null;
}

export class DialogTrapFocusHelper {
  init(dialogContainer) {
    this.dialogContainer = dialogContainer;
    document.addEventListener('keydown', this.trapFocus);
  }
  clear() {
    document.removeEventListener('keydown', this.trapFocus);
  }
  trapFocus = e => {
    const focusableElements = getFocusableElements(this.dialogContainer);
    const isUpArrowPressed = e.key === KEY_ARROW_UP || e.keyCode === KEYCODE_ARROW_UP;
    const isDownArrowPressed = e.key === KEY_ARROW_DOWN || e.keyCode === KEYCODE_ARROW_DOWN;
    const isUpDownArrowPressed = isUpArrowPressed || isDownArrowPressed;

    if (!focusableElements || !isUpDownArrowPressed) {
      return;
    }

    const lastElementIndex = focusableElements.length - 1;
    const activeElementIndex = focusableElements.findIndex(
      focusableElement => focusableElement === document.activeElement
    );
    let nextFocusableElement;

    switch (activeElementIndex) {
      case -1:
        nextFocusableElement = isUpArrowPressed ? lastElementIndex : activeElementIndex + 1;
        break;
      case 0:
        nextFocusableElement = isUpArrowPressed ? lastElementIndex : activeElementIndex + 1;
        break;
      case lastElementIndex:
        nextFocusableElement = isUpArrowPressed ? activeElementIndex - 1 : 0;
        break;
      default:
        nextFocusableElement = isUpArrowPressed ? activeElementIndex - 1 : activeElementIndex + 1;
    }

    focusableElements[nextFocusableElement].focus();
    e.preventDefault();
  };
}
