import React from 'react';
import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { SOURCES } from 'client/utils/image-helpers';
import { getNoVehicleImageToDisplay } from 'site-modules/shared/utils/get-no-vehicle-image-to-display';
import { Image } from 'site-modules/shared/components/image/image';
import { getSearchPhotoPath, GlobalSearchModel } from 'client/data/models/global-search';

import './global-search-thumbnail.scss';

export function GlobalSearchThumbnailUI({ href }) {
  const errorImage = getNoVehicleImageToDisplay();

  return (
    <div className="global-search-thumbnail mr-1">
      <Image imageId={href} source={SOURCES.MEDIA} alt="" errorImage={errorImage} className="rounded-circle" />
    </div>
  );
}

GlobalSearchThumbnailUI.propTypes = { href: PropTypes.string };
GlobalSearchThumbnailUI.defaultProps = { href: '' };

export const stateToPropsConfig = {
  href: bindToPath(({ make, model, year }) => getSearchPhotoPath({ make, model, year }), GlobalSearchModel),
};
export const GlobalSearchThumbnail = connectToModel(GlobalSearchThumbnailUI, stateToPropsConfig);
