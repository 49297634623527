import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { PageModel } from 'client/data/models/page';
import { XHeaders } from 'client/data/models/x-headers';
import { VenomVersion } from 'client/data/models/version';
import { getMetricsPageHeaders } from 'client/utils/metrics-header';

/**
 * Using special prop names in order to avoid possible prop collision.
 */
const stateToPropsConfig = {
  __metricsPageName: bindToPath('page.name', PageModel),
  __metricsPageCategory: bindToPath('page.category', PageModel),
  __metricsXTraceId: bindToPath('x-trace-id', XHeaders),
  __metricsVenomVersion: bindToPath('version', VenomVersion),
};

/**
 * HOC (High Order Component) to pass `apiMetrics` prop into child components.
 *
 * This function helps to pass the metrics into API calls that cannot use the `withMetrics` function from the
 * `client/data/api/api-metrics.js` file.
 * @param WrappedComponent child component that is wrapped by additional helper components.
 * @returns React component to render `WrappedComponent` with `apiMetrics` prop.
 */
export const withMetrics = WrappedComponent =>
  connectToModel(
    ({ __metricsPageName, __metricsPageCategory, __metricsXTraceId, __metricsVenomVersion, ...otherProps }) => {
      const metrics = useMemo(
        () => ({
          pageName: __metricsPageName,
          pageCategory: __metricsPageCategory,
          xTraceId: __metricsXTraceId,
          venomVersion: __metricsVenomVersion,
        }),
        [__metricsPageCategory, __metricsPageName, __metricsVenomVersion, __metricsXTraceId]
      );
      return <WrappedComponent {...otherProps} apiMetrics={metrics} />;
    },
    stateToPropsConfig
  );

export const ApiMetrics = PropTypes.shape({
  pageName: PropTypes.string.isRequired,
  pageCategory: PropTypes.string.isRequired,
  xTraceId: PropTypes.string.isRequired,
  venomVersion: PropTypes.string.isRequired,
});

export const getMetricApiOptions = apiMetrics => ({
  headers: getMetricsPageHeaders(apiMetrics || {}),
});
