import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { Link } from 'site-modules/shared/components/link/link';
import {
  BUTTON_DATA,
  ERROR_SCREEN_CREATIVE_ID,
  INPUT_DELAY,
  PRIMING_BOX_COPY,
  SELECT_OPTION,
} from 'site-modules/shared/constants/global-search/global-search';
import { GlobalSearchLinks } from 'site-modules/shared/components/inventory/global-search/global-search-links/global-search-links';
import { GlobalSearchLoadingSeparator } from 'site-modules/shared/components/inventory/global-search/global-search-loading-separator/global-search-loading-separator';
import { useDebounce } from 'site-modules/shared/hooks/use-debounce';
import {
  fireTemporaryClickTracking,
  fireWidgetViewTracking,
} from 'site-modules/shared/utils/inventory/global-search-tracking';
import { useDialogFocusTrap } from 'site-modules/shared/hooks/global-search/use-dialog-focus-trap';

import './global-search-results.scss';

export const GlobalSearchResults = memo(
  ({
    searchQuery,
    isMobile,
    isError,
    isOpen,
    fastMatcherData,
    isFastMatcherLoading,
    searchId,
    creativeId,
    isSelectOptionMessageShown,
    withLlmSearchDisabled,
  }) => {
    const trimmedQuery = searchQuery.trim();
    const [isResultsLoading, setIsResultsLoading] = useState(isFastMatcherLoading);
    const debouncedIsLoading = useDebounce(setIsResultsLoading, INPUT_DELAY);
    const searchResultsRef = useRef(null);
    const isLlmDisabledError = withLlmSearchDisabled && trimmedQuery && !fastMatcherData?.delta && !isResultsLoading;

    useDialogFocusTrap(searchResultsRef);

    useEffect(() => {
      if (isFastMatcherLoading) {
        setIsResultsLoading(isFastMatcherLoading);
      } else {
        debouncedIsLoading(isFastMatcherLoading);
      }

      return () => {
        if (!isFastMatcherLoading) {
          debouncedIsLoading.cancel();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFastMatcherLoading]);

    useEffect(() => {
      if (isMobile && !searchQuery) {
        fireWidgetViewTracking({ value: PRIMING_BOX_COPY, creativeId });
      }
    }, [creativeId, isMobile, searchQuery]);

    useEffect(() => {
      if (!isMobile && isOpen && !searchQuery) {
        fireWidgetViewTracking({ value: PRIMING_BOX_COPY, creativeId });
      }
    }, [creativeId, isMobile, isOpen, searchQuery]);

    useEffect(() => {
      if (isError || isLlmDisabledError) {
        fireWidgetViewTracking({ creativeId: ERROR_SCREEN_CREATIVE_ID, value: `srp_search_id=${searchId}` });
      }
    }, [isError, isLlmDisabledError, searchId]);

    useEffect(() => {
      if (isSelectOptionMessageShown) {
        fireWidgetViewTracking({ value: SELECT_OPTION, creativeId });
      }
    }, [creativeId, isSelectOptionMessageShown]);

    const fireClickTracking = useCallback(() => {
      fireTemporaryClickTracking({ creativeId });
    }, [creativeId]);

    const quickLinks = (
      <>
        <h2 className={classnames('medium font-weight-bold text-cool-gray-10 py-0_5 mb-0', { 'pl-1': !isMobile })}>
          Quick Links
        </h2>
        <ul
          className={classnames('quick-links list-unstyled mb-0 d-flex flex-column align-items-start', {
            'px-0_5': !isMobile,
          })}
        >
          {BUTTON_DATA.map(({ id, label, url, dataTrackingId }) => (
            <li className="w-100" key={id}>
              <Button
                key={id}
                tag={Link}
                color="outline"
                to={url}
                className={classnames(
                  'quick-link text-cool-gray-30 text-transform-none text-underline py-0_75 px-0 size-16',
                  {
                    'pl-0_5 w-100 text-left': !isMobile,
                  }
                )}
                data-tracking-id={dataTrackingId}
              >
                {label}
              </Button>
            </li>
          ))}
        </ul>
      </>
    );

    const selectOptionMessage = (
      <p role="alert" className="bg-blue-100 text-cool-gray-30 heading-5 px-1 py-1 mx-0 mx-md-0_5 mb-0_5 rounded-16">
        {SELECT_OPTION}
      </p>
    );

    return (
      <div
        ref={searchResultsRef}
        className={classnames('global-search-results pos-r', {
          'pt-0_5 pt-md-1': trimmedQuery || !isMobile || !isError,
        })}
      >
        <GlobalSearchLoadingSeparator isMobile={isMobile} showAnimation={!!trimmedQuery && isResultsLoading} />

        {(isError || isLlmDisabledError) && (
          <>
            <div className="mb-1_5 mt-md-1 px-md-1">
              <h3 className="heading-5">Please try adjusting your search</h3>
              <p className="size-16">No results for &quot;{searchQuery}&quot;</p>
            </div>
            {quickLinks}
          </>
        )}

        {!trimmedQuery && !isError && (
          <>
            <div
              className={classnames({
                'px-0_5': !isMobile,
              })}
            >
              <p className="priming-box heading-5 font-weight-normal text-blue-10 font-italic py-1 px-1 mb-1 rounded-16">
                {isMobile && <span className="icon-sparkles icon mr-0_5 pt-0_25 medium" aria-hidden />}
                {PRIMING_BOX_COPY}
              </p>
            </div>
            {isSelectOptionMessageShown && selectOptionMessage}

            {quickLinks}
          </>
        )}

        {trimmedQuery && !isError && !isLlmDisabledError && (
          <div aria-live="polite" aria-atomic>
            {isSelectOptionMessageShown && selectOptionMessage}

            {!withLlmSearchDisabled && (
              <button
                className={classnames(
                  'search-button w-100 d-flex align-items-center py-0_75 border-0 bg-transparent rounded-8',
                  {
                    'px-0_5': !isMobile,
                    'px-0': isMobile,
                  }
                )}
                type="submit"
                onClick={fireClickTracking}
              >
                <span className="gradient-circle d-flex align-items-center justify-content-center align-self-start rounded-circle mr-1">
                  <span className="icon-magic-search icon medium" aria-hidden />
                </span>
                <span className="d-flex text-cool-gray-30">
                  <b>search&nbsp;</b>
                  <span className="query-text text-left font-weight-normal">&quot;{trimmedQuery}&quot;</span>
                </span>
              </button>
            )}
            {!!fastMatcherData && (
              <GlobalSearchLinks
                query={trimmedQuery}
                fastMatcherData={fastMatcherData}
                creativeId={creativeId}
                isMobile={isMobile}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

GlobalSearchResults.displayName = 'GlobalSearchResults';

GlobalSearchResults.propTypes = {
  searchQuery: PropTypes.string,
  isMobile: PropTypes.bool,
  isTyping: PropTypes.bool,
  isError: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSelectOptionMessageShown: PropTypes.bool,
  fastMatcherData: PropTypes.shape({
    delta: PropTypes.string,
    struct: PropTypes.shape({}),
  }),
  isFastMatcherLoading: PropTypes.bool,
  searchId: PropTypes.string,
  creativeId: PropTypes.string,
};

GlobalSearchResults.defaultProps = {
  searchQuery: '',
  isMobile: false,
  isTyping: false,
  isError: false,
  isOpen: false,
  isSelectOptionMessageShown: false,
  fastMatcherData: null,
  isFastMatcherLoading: false,
  searchId: '',
  creativeId: '',
};
