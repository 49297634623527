import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const GlobalSearchMap = {
  shop_used_link_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.USED_CAR_SELECT
  ),
  shop_new_link_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.NEW_CAR_SELECT
  ),
  shop_reviews_link_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.ACTION_TOOL_SELECT
  ),
  shop_appraise_link_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.ACTION_APPRAISE_VEHICLE
  ),
};
