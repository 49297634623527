import React, { useRef, useCallback, useEffect, useState, Fragment, startTransition } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input } from 'reactstrap';
import classnames from 'classnames';

import './autosized-area.scss';

export const AUTOSIZED_ID = 'autosized-area';

export function AutosizedArea({
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  hintText,
  inputValue,
  className,
  inputMinHeight,
  autosizeOnFocus,
  disableOutline,
  ...rest
}) {
  const inputRef = useRef();
  const [isInitialValue, setIsInitialValue] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [currentInputHeight, setCurrentInputHeight] = useState(inputMinHeight);

  useEffect(() => {
    if (!inputValue) {
      setIsInitialValue(true);
    } else if (isInitialValue) {
      inputRef.current.style.setProperty('height', `${inputMinHeight}px`, 'important'); // this is for correct height when deleting a line
      inputRef.current.style.height = `${Math.max(inputMinHeight, inputRef.current.scrollHeight)}px`;
      setIsInitialValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, isInitialValue]);

  const handleInputChange = useCallback(
    ({ target: { value } }) => {
      onChange(value);
      inputRef.current.style.height = `${inputMinHeight}px`; // this is for correct height when deleting a line

      const height = Math.max(inputMinHeight, inputRef.current.scrollHeight);
      inputRef.current.style.height = `${height}px`;
      startTransition(() => {
        setCurrentInputHeight(height);
      });
    },
    [inputMinHeight, onChange]
  );

  useEffect(() => {
    const height = Math.max(inputMinHeight, inputRef.current.scrollHeight);
    if (isCollapsed) {
      setCurrentInputHeight(inputMinHeight);
    } else {
      setCurrentInputHeight(height);
    }
  }, [autosizeOnFocus, inputMinHeight, isCollapsed]);

  const handleFocus = useCallback(
    event => {
      onFocus(event);
      if (autosizeOnFocus) {
        setIsCollapsed(false);
      }
    },
    [autosizeOnFocus, onFocus]
  );

  const handleBlur = useCallback(
    event => {
      onBlur(event);
      if (autosizeOnFocus) {
        setIsCollapsed(true);
      }
    },
    [autosizeOnFocus, onBlur]
  );

  const handleKeydown = useCallback(
    event => {
      onKeyDown(event);
    },
    [onKeyDown]
  );

  return (
    <Fragment>
      <Input
        type="textarea"
        name="query"
        autoComplete="off"
        placeholder={hintText}
        className={classnames('autosized-area-field', className, {
          collapsed: isCollapsed && autosizeOnFocus,
          'with-shadow': autosizeOnFocus && currentInputHeight > inputMinHeight,
          'disable-outline': disableOutline,
        })}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeydown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        innerRef={inputRef}
        style={{ height: `${currentInputHeight}px` }}
        {...rest}
      />
    </Fragment>
  );
}

AutosizedArea.propTypes = {
  inputMinHeight: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  hintText: PropTypes.string,
  maxLength: PropTypes.number,
  id: PropTypes.string,
  inputValue: PropTypes.string,
  className: PropTypes.string,
  autosizeOnFocus: PropTypes.bool,
  disableOutline: PropTypes.bool,
};

AutosizedArea.defaultProps = {
  onChange: noop,
  onKeyDown: noop,
  onFocus: noop,
  onBlur: noop,
  hintText: '',
  maxLength: 200,
  id: AUTOSIZED_ID,
  inputValue: '',
  className: '',
  autosizeOnFocus: false,
  disableOutline: false,
};
