import { parseContent } from 'client/data/cms/content';

export function transformMakeModel(apiResponse) {
  const content = parseContent(apiResponse);
  const editorialReview = {};

  if (content.hasMetadata('metaDescription')) {
    editorialReview.metaDescription = content.metadata('metaDescription').value();
  }

  if (content.hasMetadata('Introduction')) {
    editorialReview.introduction = content.metadata('Introduction').value();
  }

  return editorialReview;
}
