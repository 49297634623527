import { camelCase, isEmpty } from 'lodash';

export function parseContentMetadata(metadata, keys = [], length = 1) {
  const parsedMetadata = [];
  for (let i = 1; i <= length; i += 1) {
    const dataObj = {};
    keys.forEach(key => {
      const value = metadata[`${key}-${i}`];
      if (value) dataObj[camelCase(key)] = value;
    });
    if (!isEmpty(dataObj)) parsedMetadata.push(dataObj);
  }
  return parsedMetadata;
}

export function parseLinkMetadata(entry, linkName) {
  return {
    title: entry.metadata(`${linkName}-title`).value(),
    url: entry.metadata(`${linkName}-url`).value(),
    blank: entry.metadata(`${linkName}-url-blank`).boolean(),
    nofollow: entry.metadata(`${linkName}-url-nofollow`).boolean(),
  };
}

export function parseDataPointsMetadata(entry) {
  const dataPointsNumber = Object.keys(entry.getAllMetadata()).filter(key => key.includes('data-title-')).length;
  return new Array(dataPointsNumber)
    .fill()
    .map((value, index) => ({
      title: entry.metadata(`data-title-${index + 1}`).value(),
      value: entry.metadata(`data-value-${index + 1}`).value(),
    }))
    .filter(({ value }) => value);
}

export function parseContentListLinks(entry) {
  return {
    title: entry.metadata('title').value(),
    moreLink: {
      name: entry.metadata('view-more-title').value(),
      href: entry.metadata('view-more-url').value(),
    },
    links: entry.children().map(linkContent => ({
      name: linkContent.metadata('item-title').value(),
      href: linkContent.metadata('item-link').value(),
      date: linkContent.metadata('publishDate').value(),
    })),
  };
}
