import { set, get } from 'lodash';
import { Storage } from 'site-modules/shared/utils/storage';
import { getDifferenceInHours } from 'site-modules/shared/utils/date-utils';

export const PERSONALIZED_SEARCH_PATH = 'personalized-search';

export const PersonalizedSearchStorage = {
  /**
   * Returns local storage instance
   * @returns {Storage}
   */
  getLocalStorage() {
    if (!this.localStorage) this.localStorage = new Storage('localStorage');
    return this.localStorage;
  },

  /**
   * Sets value to storage by path
   * @param {Storage} storage
   * @param {String} path
   * @param value
   */
  setValue(storage, path, value) {
    const personalizedSearchStorage = storage.get(PERSONALIZED_SEARCH_PATH) || {};
    set(personalizedSearchStorage, path, value);
    storage.set(PERSONALIZED_SEARCH_PATH, personalizedSearchStorage);
  },

  /**
   * Returns value from storage by path
   * @param {Storage} storage
   * @param {String} path
   * @returns value form storage
   */
  getValue(storage, path) {
    const personalizedSearchStorage = storage.get(PERSONALIZED_SEARCH_PATH) || {};
    return get(personalizedSearchStorage, path);
  },

  /**
   * Removes value from storage by path
   * @param {Storage} storage
   * @param {String} path
   */
  removeValue(storage, path) {
    const personalizedSearchStorage = storage.get(PERSONALIZED_SEARCH_PATH) || {};
    delete personalizedSearchStorage[path];
    storage.set(PERSONALIZED_SEARCH_PATH, personalizedSearchStorage);
  },

  /**
   * Sets value to local storage by path
   * @param {String} path
   * @param value
   */
  setValueToLocalStorage(path, value) {
    this.setValue(this.getLocalStorage(), path, value);
  },

  /**
   * Returns value from local storage by path
   * @param {String} path
   * @returns value from local storage
   */
  getValueFromLocalStorage(path) {
    return this.getValue(this.getLocalStorage(), path);
  },

  /**
   * Removes value from local storage by path
   * @param {String} path
   */
  removeValueFromLocalStorage(path) {
    return this.removeValue(this.getLocalStorage(), path);
  },

  getValueGivenExpirationDate(path) {
    const data = this.getValueFromLocalStorage(path);
    if (!data) return null;
    const { expirationDate, ...restData } = JSON.parse(data);
    if (getDifferenceInHours(expirationDate) > 0) {
      return restData;
    }
    this.removeValueFromLocalStorage(path);
    return null;
  },
};
