import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CircularProgressBar } from 'site-modules/shared/components/progress-bars/circular-progress-bar/circular-progress-bar';

import './epo-loading-indicator.scss';

export function EpoLoadingIndicator(props) {
  const { isSubmittingFinished } = props;
  return (
    <div className="epo-loading-indicator">
      <div className="epo-loading-indicator-bar-chal1 mx-auto">
        <CircularProgressBar className={classNames({ 'disable-spinner': isSubmittingFinished })}>
          <span className="d-flex align-items-center justify-content-center rounded-circle edmunds-icon">
            <i className="car-icon icon-edmunds-car text-white" aria-hidden />
          </span>
        </CircularProgressBar>
      </div>
    </div>
  );
}

EpoLoadingIndicator.propTypes = {
  isSubmittingFinished: PropTypes.bool,
};

EpoLoadingIndicator.defaultProps = {
  isSubmittingFinished: false,
};
