import { isEmpty } from 'lodash';

export const omitEmptyComponents = data => {
  const components = data?.components;

  if (!components?.length) return data;

  const filteredComponents = components.filter(componentName => {
    if (Array.isArray(data[componentName]) && data[componentName].every(isEmpty)) {
      return false;
    }

    return !isEmpty(data[componentName]);
  });

  return {
    components: filteredComponents,
    ...filteredComponents.reduce((result, component) => {
      if (Array.isArray(data[component])) {
        return {
          ...result,
          [component]: data[component].filter(item => !isEmpty(item)).map(item => omitEmptyComponents(item)),
        };
      }

      return { ...result, [component]: omitEmptyComponents(data[component]) };
    }, {}),
  };
};
