import React from 'react';
import PropTypes from 'prop-types';
import { Button as ReactstrapButton } from 'reactstrap';
import classnames from 'classnames';

import './button.scss';

export function Button(props) {
  const { className, ...rest } = props;

  return <ReactstrapButton className={classnames('inventory-button p-0_5', className)} {...rest} />;
}

Button.propTypes = {
  className: PropTypes.string,
};

Button.defaultProps = {
  className: '',
};
