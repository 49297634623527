import React from 'react';

export function decoratorParams(Decorator, customProps) {
  function DecoratorParams(props) {
    return <Decorator {...props} {...customProps} />;
  }
  DecoratorParams.preload = Decorator.preload;

  return DecoratorParams;
}
