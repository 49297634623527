import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { get } from 'lodash';
import { withMetrics } from 'client/data/api/api-metrics';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { STOCKPHOTOS_THRESHOLD_YEAR } from 'client/utils/image-helpers';
import { preferredFallbackPriority, preferredPriority } from 'site-modules/shared/utils/core-page/core-media';

export function getSearchPhotoPath({ make, model, year }) {
  return `photo["${make}"]["${model}"]["${year}"]`;
}

export const GlobalSearchModel = createModelSegment('globalSearch', [
  /**
   * @see getSearchPhotoPath
   * https://qa-11-www.edmunds.com/gateway/api/media/v2/honda/accord/2024/photos/?pageNum=1&pageSize=25&width=600&provider=OEM%2CEVOX&photoprovider=EVOX%2COEM&sortby=display_order%3Aasc%2Cid%3Aasc%2Cphotoprovider%3Aasc
   */
  {
    path: 'photo["{make}"]["{model}"]["{year}"]',
    async resolve(match, context) {
      const { make, model, year } = match;
      const shottype =
        year < STOCKPHOTOS_THRESHOLD_YEAR ? preferredPriority.concat(preferredFallbackPriority) : preferredPriority;
      const params = objectToQueryString({
        pageNum: 1,
        pageSize: 1,
        width: 175,
        provider: ['OEM', 'IZMO'],
        photoprovider: ['OEM', 'IZMO'],
        sortby: 'display_order:asc,id:asc,photoprovider:asc',
        shottype,
      });

      try {
        const res = await withMetrics(EdmundsAPI, context).fetchJson(
          `/media/v2/${make}/${model}/${year}/photos?${params}`
        );
        return get(res, 'photos[0].sources[0].link.href', '');
      } catch (e) {
        return null;
      }
    },
  },
]);
